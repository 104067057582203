@font-face {
    font-family: 'Eudoxussans';
    src: url('../fonts/EudoxusSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans';
    src: url('../fonts/EudoxusSans-ExtraBold.ttf') format('truetype'), url('../fonts/EudoxusSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans';
    src: url('../fonts/EudoxusSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans';
    src: url('../fonts/EudoxusSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussansgx';
    src: url('../fonts/EudoxusSansGX.ttf') format('truetype'), url('../fonts/EudoxusSansGX.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eudoxussans';
    src: url('../fonts/EudoxusSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.gradient-span {
    background-image: url(../images/web-app-bg.jpg);
    background-size: 250px;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: inherit;
}

.feature-item.middle-item {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 10px;
    background-color: #202020;
}

.feature-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    min-height: 350px;
    margin: 10px;
    padding: 42px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #202020;
}

.circle-hover {
    position: absolute;
    left: auto;
    top: -6vw;
    right: -6vw;
    bottom: auto;
    width: 6vw;
    height: 6vw;
    border-radius: 100%;
    background-color: #2a2c34;
}

.circle-hover.white {
    top: -6vw;
    right: -6vw;
    z-index: 0;
    background-color: #fff;
}

p * {
    color: #AAAAAA;
}

a {
    text-decoration: underline;
}

html * {
    font-size: 16px;
}

.swiper-slide {
    height: auto !important;
}